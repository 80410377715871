
import React, {useEffect} from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import RichText from '../components/richText';
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"


const Work = ({ data }) => {


  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    document.querySelector('html').classList.add('smooth');
  }, []);


  //Patch data
  if (!data) return null

  const $metaData = data.prismicMetadata.data;
  const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
  const $footer = data.prismicFooter.data;
  const $clutch = data.prismicClutch ? data.prismicClutch.data : null;
  const $socials = data.prismicSocials ? data.prismicSocials.data : null;
  const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;

  const $work = data.prismicWork.data;

  const filtersList = data.allPrismicFilter ? data.allPrismicFilter.edges : null;
  const casesList = data.allPrismicCase.edges;

  return (

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs}>

      <Seo metadata={$metaData} title={$work.page_title ? $work.page_title : null} description={$work.page_description ? $work.page_description : null} keywords={$work.page_keywords ? $work.page_keywords : null}></Seo>

      <Helmet>

        {/* No inline CSS */}
        <link href={withPrefix('../../assets/css/sitio/work.cases.css')} rel="stylesheet" type="text/css" />


      </Helmet>


      <main>
        <section className="c-portfolio o-section--b js-filter" aria-label={$work.work_kicker}>
          <div className="c-portfolio__hero c-hero o-wrapper c-heading c-heading--center">
            <h2 className="c-heading__kicker">{$work.work_kicker}</h2>
            {$work.work_title &&
              <div className="c-heading__heading">
                <RichText render={$work.work_title.richText} />
              </div>
            }
          </div> {/* .c-hero */}


          {/* FILTER */}
          {filtersList.length > 0 &&
            <nav className="c-portfolio__filters c-filter" aria-label="Filters">
              <h2 className="u-hidden-for-seo">Filtered by</h2>
              <div className="c-filter__wrapper o-wrapper">
                <ul className="c-filter__list">

                  <li className="c-filter__item">
                    <button type="button" data-filter="all" className={`c-filter__btn js-filter__btn is-active`} tabIndex={-1}>All projects</button>
                  </li>

                  {filtersList.slice(0).reverse().map((item, i) => (
                    <li key={i} className="c-filter__item">
                      <button type="button" data-filter={item.node.uid} className="c-filter__btn js-filter__btn " tabIndex={-1}>{item.node.data.filter_name}</button>
                    </li>
                  ))}

                </ul> {/* .c-filter__list */}
              </div> {/* .c-filter__wrapper */}
            </nav>
          }

          {/* LIST */}
          {casesList.length > 0 &&
            <ul className="c-portfolio__list o-wrapper">
              {casesList.map((item, i) => {

                let filters = ''
                item.node.data.case_filters.forEach(element => {
                  filters = filters != '' ? filters + `,${element.case_filter.uid}` : filters = `${element.case_filter.uid}`
                });

                return (
                  <li key={i} className="c-portfolio__item js-filter__item is-active" data-filter={filters}>
                    <article className="c-project">

                      <div className="c-project__image">
                        {item.node.data.case_color && <div className="c-project__bg" style={{ backgroundColor: `#` + item.node.data.case_color }} />}

                        <div className="c-project__pic-holder">
                          {item.node.data.case_image && item.node.data.case_image.url &&
                            <GatsbyImage className="c-project__pic c-pic" image={item.node.data.case_image.gatsbyImageData} />
                          }
                        </div>
                      </div> {/* .c-project__image */}


                      <div className="c-project__info">
                        <h2 className="c-project__title u-alt-font--bold">
                          <Link to={`${item.node.url}`} className="u-tap-size" tabIndex={-1}>{item.node.data.case_title}</Link>
                        </h2>



                        {item.node.data.case_description &&
                          <div className="c-project__description u-text">
                            <RichText render={item.node.data.case_description.richText} />
                          </div>
                        }
                        <Link to={`${item.node.url}`} className="c-project__link c-link u-tap-size">{item.node.data.case_linktitle}<span className="c-link__icon" /></Link>

                      </div> {/* .c-project__info */}
                    </article> {/* .c-project */}
                  </li>
                )
              })}

            </ul>
          }

        </section> {/* .c-portfolio */}
      </main>


    </Layout>


  )
}

export const query = graphql`
  query WorkQuery {
    prismicWork {
      data {
        page_title
        page_description
        page_keywords
        work_title {
          richText
        }
        work_kicker
      }
      uid
    },
    allPrismicFilter {
      edges {
        node {
          data {
            filter_name
          }
          uid
        }
      }
    },
    allPrismicCase(sort: {fields: first_publication_date, order: DESC}) {
      edges {
        node {
          data {
            case_color
            case_description {
              richText
            }
            case_filters {
              case_filter {
                uid
                document {
                  ... on PrismicFilter {
                    data {
                      filter_name
                    }
                    uid
                    url
                  }
                }
              }
            }
            case_image {
              gatsbyImageData(
                
                breakpoints: [414,767,768,980,1024,1440,1680,1920]
              )
              url
            }
            case_linktitle
            case_title
          }
          url
          uid
        }
      }
    },
    prismicMetadata {
      ...MetadataFragment
    },   
    prismicClutch {
      ...ClutchFragment
    },
    prismicFooter{
      ...FooterFragment   
    }
    prismicSocials{
      ...SocialsFragment   
    }
    prismicContactUs{
      ...ContactUsFragment   
    }
    prismicCalltoaction{
      ...CallToActionFragment  
    }
    
  }
`

export default Work
